import './sass/main.scss';
import {Elm} from './Main.elm';
import * as serviceWorker from './js/serviceWorker';

import * as Sentry from '@sentry/browser';


Sentry.init({dsn: 'https://ddb952e630cd46ec849ad9b9a92ad59d@o25157.ingest.sentry.io/5351812'});


const app = Elm.Main.init({
  node: document.getElementById('root'),
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


app.ports.sentry.subscribe(function (message) {
  Sentry.captureMessage(message, "error");
});
